import { RouteRecordRaw } from 'vue-router'
export default [
  {
    path: '/test',
    name: 'Test',
    component: () => import('@/views/Test/TestPage.vue'),
    children: [
      {
        path: '',
        name: 'TestHome',
        component: () => import('@/views/Test/Home/TestHome.vue'),
      },
      {
        path: 'searhCards',
        name: 'SearchCardsTest',
        component: () => import('@/views/Test/SearchCardsTest/SearchCards.vue'),
      },
      {
        path: 'hotKeys',
        name: 'HotKeysTest',
        component: () => import('@/views/Test/HotKeysTest/HotKeysTest.vue'),
      },
      {
        path: 'navPanel',
        name: 'NavPanelTest',
        component: () => import('@/views/Test/NavPanelTest/NavPanelTest.vue'),
      },
      {
        path: 'downloadPanel',
        name: 'DownloadPanelTest',
        component: () => import('@/views/Test/DownloadPanelTest/DownloadPanelTest.vue'),
      },
      {
        path: 'editor',
        name: 'EditorTest',
        component: () => import('@/views/Test/EditorTest/EditorTest.vue'),
      },
      {
        path: 'structEditor',
        name: 'StructEditorTest',
        component: () => import('@/views/Test/StructEditorTest/StructEditorTest.vue'),
      },
      {
        path: 'lazyLoading',
        name: 'LazyLoadingTest',
        component: () => import('@/views/Test/LazyLoadingTest/LazyLoadingTest.vue'),
      },
      {
        path: 'directory',
        name: 'DirectoryTest',
        component: () => import('@/views/Test/DirectoryTest/DirectoryTest.vue'),
      },
      {
        path: 'searchListItem',
        name: 'SearchListItemTest',
        component: () => import('@/views/Test/SearchListItemTest/SearchListItemTest.vue'),
      },
      {
        path: 'lazyCommon',
        name: 'LazyLoadingCommonModeTest',
        component: () => import('@/views/Test/LazyLoadingCommonModeTest/LazyLoadingCommonModeTest.vue'),
      },
      {
        path: 'advanceRequest',
        name: 'AdvancedRequestTest',
        component: () => import('@/views/Test/AdvancedRequestTest/AdvancedRequestTest.vue'),
      },
      {
        path: 'GoFilterTest',
        name: 'GoFilterTest',
        component: () => import('@/views/Test/GoFilterTest/GoFilterTest.vue'),
      },
      {
        path: 'literatureFilterTest',
        name: 'literatureFilterTest',
        component: () => import('@/views/Test/literatureFilterTest/literatureFilterTest.vue'),
      },
      {
        path: '3DstructureTest',
        name: '3DstructureTest',
        component: () => import('@/views/Test/3DstructureTest/3DstructureTest.vue'),
      },
      {
        path: 'DataOverviewCompundTest',
        name: 'DataOverviewCompundTest',
        component: () => import('@/views/Test/DataOverviewCompundTest/DataOverviewCompundTest.vue'),
      },
      {
        path: 'DataOverviewFoodTest',
        name: 'DataOverviewFoodTest',
        component: () => import('@/views/Test/DataOverviewFoodTest/DataOverviewFoodTest.vue'),
      },
      {
        path: 'DataOverviewPlantTest',
        name: 'DataOverviewPlantTest',
        component: () => import('@/views/Test/DataOverviewPlantTest/DataOverviewPlantTest.vue'),
      },
      {
        path: 'Register',
        name: 'Register',
        component: () => import('@/views/Test/Register/Register.vue'),
      },
      // {
      //   path: 'MSA',
      //   name: 'MSA',
      //   component: () => import('@/views/Test/MSA/msa.vue'),
      // },
      // {
      //   path: 'MSAResult',
      //   name: 'MSAResult',
      //   component: () => import('@/views/Test/MSAResult/index.vue'),
      // },
      {
        path: 'testMSA',
        name: 'testMSA',
        component: () => import('@/views/Test/testMSA/testMSA.vue'),
      },
      {
        path: 'ChartVisualization',
        name: 'ChartVisualization',
        component: () => import('@/views/Test/ChartVisualization/Index.vue'),
        redirect: { name: 'useProps' },
        children: [
          {
            path: '',
            name: 'useProps',
            component: () => import('@/views/Test/ChartVisualization/ChartVisualization.vue'),
          },
          {
            path: 'noProps',
            name: 'noProps',
            component: () => import('@/views/Test/ChartVisualization/ChartVisualization_noProps.vue'),
          },
        ],
      },
      {
        path: 'table',
        name: 'TableTest',
        component: () => import('@/views/Test/TableTest/TableTest.vue'),
      },
      {
        path: 'PDBeMolstar',
        name: 'PDBeMolstarTest',
        component: () => import('@/views/Test/PDBeMolstarTest/PDBeMolstarTest.vue'),
      },
      {
        path: 'PDBeMolstarCustomData',
        name: 'PDBeMolstarCustomDataTest',
        component: () => import('@/views/Test/PDBeMolstarCustomDataTest/PDBeMolstarCustomDataTest.vue'),
      },
      {
        path: 'portvista',
        name: 'portvistaTest',
        component: () => import('@/views/Test/PortvistaTest/PortvistaTest.vue'),
      },
      {
        path: 'AminoAcidSequenceInformation',
        name: 'AminoAcidSequenceInformation',
        component: () => import('@/views/Test/AminoAcidSequenceInformation/AminoAcidSequenceInformation.vue'),
      },

      {
        path: '3DMol',
        name: '3DMolTest',
        component: () => import('@/views/Test/3DMolTest/3DMolTest.vue'),
      },
      {
        path: 'HumanDiseasesDetails',
        name: 'HumanDiseasesDetailsTest',
        component: () => import('@/views/Test/HumanDiseasesDetailsTest/HumanDiseasesDetails.vue'),
      },
      {
        path: 'ProteinTargetDetails',
        name: 'ProteinTargetDetailsTest',
        component: () => import('@/views/Test/ProteinTargetDetailsTest/ProteinTargetDetails.vue'),
      },
      // {
      //     path:'BioassayDetailsTest/:id',
      //     name:'BioassayDetails',
      //     component:()=>import('@/views/Test/BioassayDetailsTest/BioassayDetails.vue'),

      // },
      {
        path: 'CellLineDetails',
        name: 'CellLineDetailsTest',
        component: () => import('@/views/Test/CellLineDetailsTest/CellLineDetails.vue'),
      },
      {
        path: 'SViewer',
        name: 'SViewer',
        component: () => import('@/views/Test/SViewerTest/SViewerTest.vue'),
      },
      {
        path: 'ProSeqViewer',
        name: 'ProSeqViewer',
        component: () => import('@/views/Test/ProSeqViewer/ProSeqViewer.vue'),
      },
      {
        path: 'Clipboard',
        name: 'Clipboard',
        component: () => import('@/views/Test/ClipboardTest/ClipboardTest.vue'),
      },
      {
        path: 'TreeTest',
        name: 'TreeTest',
        component: () => import('@/views/Test/TreeTest/Tree.vue'),
      },
      {
        path: 'gsap',
        name: 'gsap',
        component: () => import('@/views/Test/GSAPtest/GSAP.vue'),
      },
      {
        path: 'Tree2',
        name: 'Tree2',
        component: () => import('@/views/Test/TreeTest2/Tree.vue'),
      },
      {
        path: 'vue-request',
        name: 'vue-request',
        component: () => import('@/views/Test/vueRequestTest/VueRequest.vue'),
      },
      {
        path: 'tree-of-life',
        name: 'tree-of-life',
        component: () => import('@/views/Test/TreeOfLife/TreeOfLife.vue'),
      },
      {
        path: 'taxonomy',
        name: 'taxonomyTest',
        component: () => import('@/views/Test/Taxonomy/index.vue'),
      },
      {
        path: 'ChemicalReactionPrediction',
        name: 'ChemicalReactionPrediction',
        component: () => import('@/views/Test/ChemicalReactionPrediction/ChemicalReactionPrediction.vue'),
      },
      // {
      //   path: 'Gene3D',
      //   name: 'Gene3D',
      //   component: () => import('@/views/Test/Gene3D/index.vue'),
      // },
      {
        path: 'AlphaFold',
        name: 'AlphaFold',
        component: () => import('@/views/Test/AlphaFold/index.vue'),
      },
      {
        path: 'GeneNewDetails',
        name: 'GeneNewDetails',
        component: () => import('@/views/Test/GeneNewDetails/GeneNewDetails.vue'),
      },
      {
        path: 'sss',
        name: 'sss',
        component: () => import('@/views/Home/components/home-search/index.vue'),
      },
      {
        path: '3DMolPdb',
        name: '3DMolPdb',
        component: () => import('@/views/Test/3DMolPdb/mol2.vue'),
      },
      {
        path: 'generatorPng',
        name: 'generatorPng',
        component: () => import('@/views/Test/AlphaFold/generatorPng.vue'),
      },
    ],
  },
] as RouteRecordRaw[]
